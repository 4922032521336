import gql from 'graphql-tag'

export default gql `{
  name
  flag
  from
  messages{
    body
    date
    time
    read
    flag_to
    author{
      name
      photo
      id
    }
  }
}`